<template>
  <b-container fluid>
    <b-row class="mb-3">
      <b-col cols="6">
        <h1 class="font-weight-bold text-primary">View Supply Order</h1>
      </b-col>
      <b-col cols="6">
        <div v-if="hasAnyPermission(['update-supply-order'])"
             class="d-none d-md-flex flex-row justify-content-end pt-2">
          <b-button v-b-popover.bottom="'Edit this supply Order.'"
                    :to="{
              name: 'dash.supply_orders.update',
              params: { id: supplierOrder.id }
            }"
                    class="mr-1 mb-0"
                    size="sm"
                    variant="primary"
          >
            <b-icon icon="pen"/>
            Edit Supply Order
          </b-button
          >
        </div>
      </b-col>
    </b-row>
    <b-row v-if="isLoading">
      <b-col cols="12">
        <p class="text-center">
          <b-spinner variant="secondary"></b-spinner>
        </p>
      </b-col>
    </b-row>
    <b-row v-if="!isLoading">
      <b-col cols="12">
        <b-row>
          <b-col cols="12">
            <b-form-group
              :invalid-feedback="
                validationInvalidFeedback(errors.errors, 'name')
              "
              label="Name"
            >
              <b-form-input
                v-model="supplierOrder.name"
                :state="validationState(errors.errors, 'name')"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Supply Order Item -->
        <b-row class="mb-4">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col
                      class="d-flex flex-row"
                      cols="12"
                      role="button"
                      @click.prevent="toggleCollapse('supply_items')"
                    >
                      <b-icon
                        :icon="
                          collapsed['supply_items']
                            ? 'chevron-right'
                            : 'chevron-down'
                        "
                        class="align-self-center cursor-pointer text-muted"
                        font-scale="1.5"
                      ></b-icon>
                      <div class="d-flex flex-column flex-fill ml-4">
                        <p class="h4 font-weight-bold mb-0">Supply Items</p>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row v-if="!collapsed['supply_items']" class="mt-4">
                    <b-col
                      v-if="!supplierOrder.supply_order_items.length"
                      cols="12"
                    >
                      <hr class="mt-0"/>
                      <b-row>
                        <b-col class="text-center" cols="12">
                          <b-card class="py-3 text-center">
                            <p class="mb-4">
                              <fa-icon
                                class="display-1 text-dark"
                                icon="times"
                              ></fa-icon>
                            </p>
                            <p class="font-weight-normal h5 mb-0 text-dark">
                              There are no supply items.
                            </p>
                          </b-card>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col
                      v-if="supplierOrder.supply_order_items.length"
                      cols="12"
                    >
                      <b-table-simple hover responsive striped>
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th>Quantity</th>
                          <th>Status</th>
                          <th>Date</th>
                          <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                          v-for="(supply,
                            supplyIndex) in supplierOrder.supply_order_items"
                          :key="supplyIndex"
                        >
                          <td>#{{ supply.id }}</td>
                          <td>
                            {{ supply.name }}
                            <div class="input-group-append">
                                <span
                                  v-if="!supply.product"
                                  class="text-muted"
                                  style="display: block; font-size: 12px !important; text-align: left !important"
                                >No Product</span
                                >
                              <span
                                v-else
                                class="text-muted"
                                style="display: block; font-size: 12px !important; text-align: left !important"
                              >
                                  Product:
                                  {{ supply.product.name }}</span
                              >
                            </div>
                            <div class="input-group-append">
                                <span
                                  v-if="
                                    !supply.product || !supply.product.range
                                  "
                                  class="text-muted"
                                  style="display: block; font-size: 12px !important; text-align: left !important"
                                >No Range</span
                                >
                              <span
                                v-else
                                class="text-muted"
                                style="display: block; font-size: 12px !important; text-align: left !important"
                              >Range: {{ supply.product.range }}</span
                              >
                            </div>
                            <div class="input-group-append">
                                <span
                                  v-if="
                                    !supply.product || !supply.product.color
                                  "
                                  class="text-muted"
                                  style="display: block; font-size: 12px !important; text-align: left !important"
                                >No Colour</span
                                >
                              <span
                                v-else
                                class="text-muted"
                                style="display: block; font-size: 12px !important; text-align: left !important"
                              >
                                  Color:
                                  {{
                                  supply ? supply.color : supply.color
                                }}</span
                              >
                            </div>

                            <div class="input-group-append">
                                <span
                                  v-if="
                                    !supply.product || !supply.product.design
                                  "
                                  class="text-muted"
                                  style="display: block; font-size: 12px !important; text-align: left !important"
                                >No Design</span
                                >
                              <span
                                v-else
                                class="text-muted"
                                style="display: block; font-size: 12px !important; text-align: left !important"
                              >Design:
                                  {{
                                  supply ? supply.design : supply.design
                                }}</span
                              >
                            </div>
                          </td>
                          <td v-if="supply.measurement_types_id === 1">
                            {{ supply.quantity }}
                            <div class="input-group-append">
                                <span class="text-muted"
                                >L: {{ supply.length }} X W:
                                  {{ supply.width }}</span
                                >
                            </div>
                            <p v-if="supply.quantity <= 0">
                              This supply order has been fully returned.
                            </p>
                          </td>
                          <td v-else-if="supply.measurement_types_id === 2">
                            <p>{{ supply.quantity }} Packs</p>
                            <p v-if="supply.quantity <= 0">
                              This supply order has been fully returned.
                            </p>
                          </td>

                          <td v-else>
                            <p>
                              {{ supply.quantity }}
                            </p>
                            <p v-if="supply.quantity <= 0">
                              This supply order has been fully returned.
                            </p>

                            <div class="input-group-append">
                                <span class="text-muted"
                                >{{ supply.quantity }} Items</span
                                >
                            </div>
                          </td>
                          <td :class="rowClass(supply)">
                            {{ supply.status }}
                          </td>
                          <td>
                            {{ moment(supply.date).format("Do MMM YYYY") }}
                          </td>
                          <td>
                            <b-button
                              v-if="
                                  supply.measurement_types_id !== 1 ||
                                    !supply.product_variation_id
                                "
                              class="mr-1 mb-0"
                              size="sm"
                              variant="secondary"
                              @click="openSupplyModal(supply, supplyIndex)"
                            >
                              <b-icon icon="box"/>
                              Process Return
                            </b-button
                            >
                            <b-button
                              v-if="
                                  supply.measurement_types_id === 1 &&
                                    supply.product_variation_id
                                "
                              class="mr-1 mb-0"
                              size="sm"
                              variant="secondary"
                              @click="openLotModal(supply, supplyIndex)"
                            >
                              <b-icon icon="box"/>
                              Manage Lots
                            </b-button
                            >
                          </td>
                        </tr>
                        </tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Return Items Modal -->
    <b-modal
      id="returnItemsModal"
      hide-footer
      title="Return Items"
      width="2/5"
      width-lg="3/5"
    >
      <b-form @submit.prevent="submit">
        <b-form-group
          :invalid-feedback="
            validationInvalidFeedback(returnErrors.errors, 'quantity')
          "
          label="Quantity"
        >
          <b-form-input
            v-model="supplierReturns.quantity"
            :state="validationState(returnErrors.errors, 'quantity')"
          />
        </b-form-group>

        <b-form-group
          :invalid-feedback="
            validationInvalidFeedback(returnErrors.errors, 'note')
          "
          label="Note"
        >
          <b-form-input
            v-model="supplierReturns.note"
            :state="validationState(returnErrors.errors, 'note')"
          />
        </b-form-group>

        <b-form-group
          :invalid-feedback="
            validationInvalidFeedback(returnErrors.errors, 'reference')
          "
          label="Supplier Reference"
        >
          <b-form-input
            v-model="supplierReturns.reference"
            :state="validationState(returnErrors.errors, 'reference')"
          />
        </b-form-group>

        <b-card v-if="computedQuantityRequiringFix > 0" class="mb-3">
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-row">
                <div class="flex-fill">
                  <h4 class="mb-3">Order Items</h4>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-card class="text-center">
                <h5>Total To Re-Assign</h5>
                {{ computedQuantityRequiringFix }}
              </b-card>
            </b-col>
            <b-col cols="12" md="6">
              <b-card class="text-center">
                <h5>Still To Re-Assign</h5>{{ computedQuantityRequiringFix - supplierReturnsQuantitiesSum }}
              </b-card>
            </b-col>
          </b-row>
          <b-list-group class="mt-3">
            <b-list-group-item
              v-for="orderItem in supplierReturns.supply.order_items"
              :key="`order-item-return-${orderItem.id}`"
            >
              <b-row>
                <b-col class="d-flex align-items-center" cols="7">
                  <p class="text-bold mb-0">
                    <router-link :to="{name: 'dash.orders.view', params: {id: orderItem.order_id}}">Order Item:
                      #{{ orderItem.id }}
                    </router-link>
                  </p>
                </b-col>
                <b-col cols="5">
                  <b-form-spinbutton
                    v-model="supplierReturnsQuantities[orderItem.id]"
                    min="0"
                    @input="setSumOfSupplierReturnQuantities"
                  ></b-form-spinbutton>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-card>

        <b-form-checkbox
          id="re-order-input"
          v-model="supplierReturns.reorder"
          class="mb-3"
        >
          Re Order Supply
        </b-form-checkbox>

        <b-form-checkbox
          id="return-to-stock"
          v-model="supplierReturns.returnToStock"
          class="mb-3"
        >
          Return to Stock
        </b-form-checkbox>
        <span v-if="supplierReturns.returnToStock" class="mb-3 warning"
        >This will <strong>add</strong> {{ supplierReturns.quantity }} to the
          internal stock.</span
        >

        <div class="col-span-2">
          <div class="flex flex-row ml-auto space-x-2 w-full lg:w-96">
            <b-button
              block
              class="align-self-end mb-3"
              type="submit"
              variant="primary"
            >
              <b-spinner v-if="isSaving" small></b-spinner
              >
              <span v-if="!isSaving">Save</span></b-button
            >
          </div>
        </div>
      </b-form>
    </b-modal>

    <!-- Lot Modal -->
    <b-modal
      id="lotModal"
      hide-footer
      title="Manage Supply Order Item Lots"
      width="2/5"
      width-lg="3/5"
    >
      <b-row>
        <b-col v-if="lotModalSupply" cols="12">
          <h5>{{ lotModalSupply.name }} Lots</h5>
          <b-card>
            <b-table-simple>
              <b-thead>
                <b-tr>
                  <b-th>Lot ID</b-th>
                  <b-th>Length</b-th>
                  <b-th>Actions</b-th>
                </b-tr>
              </b-thead>
              <b-tbody
                v-for="(lot, lotInd) in lotModalSupply.lots"
                :key="`lot${lotInd}`"
              >
                <b-tr>
                  <b-td>
                    <div class="d-flex flex-column">
                      <p>#{{ lot.id }}</p>
                      <small
                        v-if="lot.order_item_id && !Boolean(lot.discarded)"
                        class="text-muted"
                      >This lot is assigned to an order item.</small
                      >
                    </div>
                  </b-td>
                  <b-td>
                    <b-form-input
                      v-model="lot.length"
                      :disabled="lot.discarded === 1"
                      placeholder="Length"
                    />
                  </b-td>
                  <b-td>
                    <b-button
                      :disabled="Boolean(lot.discarded)"
                      :variant="lot.discarded ? 'secondary' : 'danger'"
                      @click="doDiscardLot(lotInd)"
                    >{{
                        lot.discarded ? "Discarded" : "Discard Lot"
                      }}
                    </b-button
                    >
                    <b-form-checkbox v-model="lot.return.do_return"
                    >Return Lot
                    </b-form-checkbox
                    >
                  </b-td>
                </b-tr>
                <b-tr v-if="lot.return.do_return">
                  <b-td colspan="3">
                    <b-row>
                      <b-col cols="12">
                        <b-form-group
                          :invalid-feedback="
                            validationInvalidFeedback(
                              returnErrors.errors,
                              'note'
                            )
                          "
                          label="Note"
                        >
                          <b-form-input
                            v-model="lot.return.note"
                            :state="
                              validationState(returnErrors.errors, 'note')
                            "
                          />
                        </b-form-group>

                        <b-form-group
                          :invalid-feedback="
                            validationInvalidFeedback(
                              returnErrors.errors,
                              'reference'
                            )
                          "
                          label="Supplier Reference"
                        >
                          <b-form-input
                            v-model="lot.return.reference"
                            :state="
                              validationState(returnErrors.errors, 'reference')
                            "
                          />
                        </b-form-group>

                        <b-form-checkbox
                          id="return-to-stock"
                          v-model="lot.return.returnToStock"
                          class="mb-3"
                        >
                          Return to Stock
                        </b-form-checkbox>
                        <span
                          v-if="lot.return.returnToStock"
                          class="mb-3 warning"
                        >This will <strong>add</strong> {{ lot.length }} to
                          the internal stock.</span
                        >
                      </b-col>
                    </b-row>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-button block class="mt-3" @click="addLot()">Add Lot</b-button>
          </b-card>
          <b-button class="mt-3" variant="primary" @click="saveLotModal()"
          >
            <b-spinner v-if="isSaving" small></b-spinner
            >
            <span v-if="!isSaving">Save</span></b-button
          >
        </b-col>
      </b-row>
    </b-modal>

    <!-- Activity Log -->
    <b-row v-if="computedAccessLogs" class="mb-4">
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col
              class="d-flex flex-row"
              cols="12"
              role="button"
              @click.prevent="toggleCollapse('access_logs')"
            >
              <b-icon
                :icon="
                  collapsed['access_logs'] ? 'chevron-right' : 'chevron-down'
                "
                class="align-self-center cursor-pointer text-muted"
                font-scale="1.5"
              ></b-icon>
              <div class="d-flex flex-column flex-fill ml-4">
                <p class="h4 font-weight-bold mb-0">Activity Log</p>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="!collapsed['access_logs']" class="mt-4">
            <b-col v-if="!supplierOrder.access_logs.length" cols="12">
              <b-row>
                <b-col class="text-center" cols="12">
                  <b-card class="py-3 text-center">
                    <p class="mb-4">
                      <fa-icon
                        class="display-1 text-dark"
                        icon="times"
                      ></fa-icon>
                    </p>
                    <p class="font-weight-normal h5 mb-0 text-dark">
                      There are no access logs.
                    </p>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-if="supplierOrder.access_logs.length" cols="12">
              <b-table-simple hover responsive striped>
                <thead>
                <tr>
                  <th>Description</th>
                  <th>User</th>
                  <th>Date</th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="(log, logInd) in supplierOrder.access_logs"
                  :key="logInd"
                >
                  <td>{{ log.description }}</td>
                  <td>{{ log.users.name }}</td>
                  <td>{{ moment(log.created_at).format("Do MMM YYYY, h:mm:ss a") }}</td>
                </tr>
                </tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import validation from "../../mixins/validation";
import {mapActions, mapGetters} from "vuex";
import momentMixin from "../../mixins/momentMixin";

export default {
  mixins: [validation, momentMixin],
  mounted() {
    this.fetchOrders(this.$route.params.id);

    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      switch (modalId) {
        case "returnItemsModal":
          this.supplierReturns.supply_order_item_id = null;
          this.supplierReturns.quantity = null;
          this.supplierReturns.note = null;
          this.supplierReturns.reference = null;
          this.supplierReturns.reorder = false;
          this.supplierReturns.returnToStock = false;
          break;
        case "lotModal":
          this.lotModalSupply = null;
          break;
      }
    });
  },
  computed: {
    ...mapGetters("authentication", ["hasAnyPermission"]),
    ...mapGetters("supply-orders", [
      "errors",
      "isLoading",
      "isSaving",
      "single"
    ]),
    ...mapGetters("supply-returns", {
      returnErrors: "errors",
      returnLoading: "isLoading",
      returnSaving: "isSaving",
      returnSingle: "single"
    }),

    computedAccessLogs() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.supplierOrder.hasOwnProperty("access_logs")) {
        return this.supplierOrder.access_logs;
      }
      return false;
    },

    computedQuantityAttached() {
      if (this.supplierReturns.supply) {
        let quantityAttached = 0;
        // this.supplierReturns.supply.order_items gives the quantity from the order item
        if (this.supplierReturns.supply.order_items) {
          this.supplierReturns.supply.order_items.forEach(function (orderItem) {
            // Assuming we are adding together the order item quantities
            quantityAttached += Number(orderItem.pivot.quantity);
          });
          return quantityAttached;
        }
      }
      return 0;
    },

    //  This whole function just takes the total from the order and takes away the amount on the supply order which tells you if you have stock still to order
    computedQuantityRequiringFix() {
      if (this.supplierReturns.supply) {
        if (this.supplierReturns.supply.order_items) {
          if (this.computedQuantityAttached) {
            let amountAfterReturn =
              this.supplierReturns.supply.quantity -
              this.supplierReturns.quantity;

            if (amountAfterReturn < this.computedQuantityAttached) {
              return this.computedQuantityAttached - amountAfterReturn;
            }
          }
        }
      }
      return 0;
    }
  },
  data() {
    return {
      collapsed: {
        supply_items: !this.$route.params.id,
        access_logs: !this.$route.params.id
      },

      supplierOrder: {
        name: null,
        product_id: null,
        product: [],
        supply_order_items: []
      },

      supplierReturns: {
        supply_order_item_id: null,
        quantity: null,
        note: null,
        reference: null,
        reorder: false,
        returnToStock: false,
        supply: {}
      },

      supplierReturnsQuantities: {},
      supplierReturnsQuantitiesSum: 0,

      lotModalSupply: null,

      deletedIds: []
    };
  },
  methods: {
    ...mapActions("supply-orders", {
      fetchOrders: "fetch",
      saveSupplyOrderItemLots: "saveSupplyOrderItemLots"
    }),
    ...mapActions("supply-returns", {
      fetchReturns: "fetch",
      saveReturns: "save"
    }),

    setSumOfSupplierReturnQuantities() {
      let sum = 0;
      Object.keys(this.supplierReturnsQuantities).forEach(key => {
        sum += this.supplierReturnsQuantities[key];
      });
      this.supplierReturnsQuantitiesSum = sum;
    },

    openSupplyModal(supply) {
      this.supplierReturnsQuantities = {};

      this.supplierReturns.supply = supply;
      this.supplierReturns.supply_order_item_id = supply.id;
      this.supplierReturns.product_id = supply.product_id;

      this.$bvModal.show("returnItemsModal");
    },

    openLotModal(supply) {
      let noRefSupply = JSON.parse(JSON.stringify(supply));

      noRefSupply.lots.forEach((lot, lotInd) => {
        noRefSupply.lots[lotInd]["return"] = {
          supply_order_lot_id: lot.supply_order_item_id,
          do_return: false,
          note: null,
          reference: null,
          reorder: false,
          returnToStock: false
        };
      });

      this.lotModalSupply = noRefSupply;
      this.$bvModal.show("lotModal");
    },

    doDiscardLot(lotIndex) {
      if (this.lotModalSupply.lots[lotIndex]["id"]) {
        this.lotModalSupply.lots[lotIndex].discarded = 1;
      } else {
        this.lotModalSupply.lots.splice(lotIndex, 1);
      }
    },

    saveLotModal() {
      this.saveSupplyOrderItemLots(this.lotModalSupply).then(() => {
        this.fetchOrders(this.$route.params.id).then(() => {
          this.$bvModal.hide("lotModal");
        });
      });
    },

    addLot() {
      this.lotModalSupply.lots.push({
        id: null,
        length: null,
        order_item_id: null,
        supply_order_item_id: this.lotModalSupply.id,
        discarded: 0,
        return: {
          do_return: false,
          returnToStock: false
        }
      });
    },

    submit() {
      let data = {
        id: this.$route.params.id,
        supply_order_item_id: this.supplierReturns.supply_order_item_id,
        product_id: this.supplierReturns.product_id,
        supplier_id: this.supplierReturns.supplier_id,
        note: this.supplierReturns.note,
        quantity: this.supplierReturns.quantity,
        reference: this.supplierReturns.reference,
        reorder: this.supplierReturns.reorder,
        returnToStock: this.supplierReturns.returnToStock,
        order_items: this.supplierReturnsQuantities
      };

      if (
        this.computedQuantityRequiringFix === 0 || this.computedQuantityRequiringFix - this.supplierReturnsQuantitiesSum === 0
      ) {
        this.saveReturns(data).then(response => {
          if ([200, 201].includes(response.status)) {
            this.fetchOrders(this.$route.params.id).then(() => {
              this.$bvModal.hide("returnItemsModal");
            });
          }
        });
      }
    },

    rowClass(supply) {
      if (supply.status === "Ordered") {
        this.class = "ordered";
        return this.class;
      }
      if (supply.status === "Goods In") {
        this.class = "goods-in";
        return this.class;
      }
      if (supply.status === "Partial Goods In") {
        this.class = "part-goods-in";
        return this.class;
      }
      if (supply.status === "Faulted") {
        this.class = "faulted";
        return this.class;
      }
    },

    toggleCollapse(name) {
      this.collapsed[name] = !this.collapsed[name];
    },

    toggleIsDeleted(id) {
      if (this.deletedIds.includes(id)) {
        this.deletedIds.splice(this.deletedIds.findIndex(id), 1);
      } else {
        this.deletedIds.push(id);
      }
    }
  },
  watch: {
    single() {
      this.supplierOrder = this.single;
    }
  }
};
</script>

<style>
.deletedItem {
  background-color: red;
}

.ordered {
  color: white;
  background-color: #ffbf00;
}

.goods-in {
  color: white;
  background-color: #00ff00;
}

.part-goods-in {
  color: white;
  background-color: #3792cb;
}

.faulted {
  color: white;
  background-color: #ff0000;
}
</style>
